<template>
   <section class="works-area p-5">
      <div class="container">
         <h1 class="mb-5">OUR WORKS</h1>


         <h3 class="work_title mb-5">Korek Telecom</h3>
         <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000">
            <Slide v-for="item in 13" :key="item">
               <div class="carousel__item">
                  <img @click="openModal(`https://magency.s3.eu-central-1.amazonaws.com/images/Korek/korek${item}.jpg`)"
                     :src="`https://magency.s3.eu-central-1.amazonaws.com/images/Korek/korek${item}.jpg`" alt="images">
               </div>
            </Slide>

            <template #addons>
               <Navigation />
            </template>
         </Carousel>


         <h3 class="work_title mb-5 mt-5">TipTop</h3>
         <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000">
            <Slide v-for="item in 20" :key="item">
               <div class="carousel__item">
                  <img @click="openModal(`https://magency.s3.eu-central-1.amazonaws.com/images/Tiptop/tiptop${item}.jpg`)"
                     :src="`https://magency.s3.eu-central-1.amazonaws.com/images/Tiptop/tiptop${item}.jpg`" alt="images">
               </div>
            </Slide>

            <template #addons>
               <Navigation />
            </template>
         </Carousel>



         <h3 class="work_title mb-5 mt-5">Bye Bye</h3>
         <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000">
            <Slide v-for="item in 13" :key="item">
               <div class="carousel__item">
                  <img @click="openModal(`https://magency.s3.eu-central-1.amazonaws.com/images/byebye/bye${item}.jpg`)"
                     :src="`https://magency.s3.eu-central-1.amazonaws.com/images/byebye/bye${item}.jpg`" alt="images">
               </div>
            </Slide>

            <template #addons>
               <Navigation />
            </template>
         </Carousel>


         <h3 class="work_title mb-5 mt-5">AUK Giving</h3>
         <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000">
            <Slide v-for="item in 10" :key="item">
               <div class="carousel__item">
                  <img @click="`https://magency.s3.eu-central-1.amazonaws.com/images/AUK/auk${item}.jpg`"
                     :src="`https://magency.s3.eu-central-1.amazonaws.com/images/AUK/auk${item}.jpg`" alt="images">
               </div>
            </Slide>

            <template #addons>
               <Navigation />
            </template>
         </Carousel>

         <h3 class="work_title mb-5 mt-5">SuperFast</h3>
         <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000">
            <Slide v-for="item in 6" :key="item">
               <div class="carousel__item">
                  <img
                     @click="openModal(`https://magency.s3.eu-central-1.amazonaws.com/images/Superfast/superfast${item}.jpeg`)"
                     :src="`https://magency.s3.eu-central-1.amazonaws.com/images/Superfast/superfast${item}.jpeg`"
                     alt="images">
               </div>
            </Slide>

            <template #addons>
               <Navigation />
            </template>
         </Carousel>


         <h3 class="work_title mb-5 mt-5">Tornet</h3>
         <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000">
            <Slide v-for="item in 15" :key="item">
               <div class="carousel__item">
                  <img @click="openModal(`https://magency.s3.eu-central-1.amazonaws.com/images/Tornet/tornet${item}.jpg`)"
                     :src="`https://magency.s3.eu-central-1.amazonaws.com/images/Tornet/tornet${item}.jpg`" alt="images">
               </div>
            </Slide>

            <template #addons>
               <Navigation />
            </template>
         </Carousel>

         <h3 class="work_title mb-5 mt-5">Torliga</h3>
         <Carousel v-bind="settings" :breakpoints="breakpoints" :autoplay="2000">
            <Slide v-for="item in 10" :key="item">
               <div class="carousel__item">
                  <img
                     @click="openModal(`https://magency.s3.eu-central-1.amazonaws.com/images/Torliga/torliga${item}.jpg`)"
                     :src="`https://magency.s3.eu-central-1.amazonaws.com/images/Torliga/torliga${item}.jpg`" alt="images">
               </div>
            </Slide>

            <template #addons>
               <Navigation />
            </template>
         </Carousel>





      </div>
   </section>


   <!-- Modal -->
   <div v-if="showModal" class="modal" @click="closeModal">
      <div class="modal-content" @click.stop>
         <span class="close" @click="closeModal">&times;</span>
         <img :src="selectedImage" class="modal-image">
      </div>
   </div>
</template>



<style scoped>
.works-area {
   padding: 90px !important;
   background: transparent !important;
}


.carousel__item {
   margin: 0px 6px !important;
   width: 300px;
   /* Set as per your image size */
   overflow: hidden !important;
}

.carousel__item img {
   border-radius: 10px !important;
   width: 100% !important;
   /* Adjust based on your layout */
   filter: grayscale(100%) !important;
   /* Make the image grayscale */
   transition: all 0.5s ease !important;
   cursor: pointer !important;

}


.carousel__item img:hover {
   filter: grayscale(0) !important;
   /* Remove the grayscale filter to show colors */
   transform: scale(1.1) !important;
}

h1 {
   font-size: 30px !important;
   font-weight: bolder !important;
   color: white;
}

.work_title {
   font-size: 25px !important;
   font-weight: bolder !important;
   color: white;
   text-align: start !important;
}



.modal {
   display: none;
   /* Hidden by default */
   position: fixed;
   /* Stay in place */
   z-index: 100000;
   /* Sit on top */
   left: 0;
   top: 0;
   width: 100%;
   /* Full width */
   height: 100%;
   /* Full height */
   overflow: auto;
   /* Enable scroll if needed */
   background-color: rgba(0, 0, 0, 0.8);
   /* Black w/ opacity */
   display: flex;
   /* Added to center the modal content */
   justify-content: center;
   /* Center horizontally */
   align-items: center;
   /* Center vertically */
}

.modal-content {
   position: relative;
   max-width: 90%;
   max-height: 90%;
   background: transparent !important;
   border: none !important;
   outline: none !important;
}

.close {
   position: absolute;
   top: 10px;
   right: 15px;
   font-size: 30px;
   color: white;
   cursor: pointer;
}

.modal-image {
   max-width: 100%;
   /* ensures the image is not wider than the viewport */
   max-height: 80vh;
   /* limits the image height to 80% of the viewport height */
   object-fit: contain;

}
</style>




<script>
import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default {
   methods: {
      openModal(image) {
         this.selectedImage = image;
         this.showModal = true;
      },
      closeModal() {
         this.showModal = false;
      },
   },
   name: 'Breakpoints',
   components: {
      Carousel,
      Slide,
      Navigation,
   },
   data: () => ({

      showModal: false,
      selectedImage: null,



      // carousel settings
      settings: {
         itemsToShow: 1,
         snapAlign: 'center',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
         // 700px and up
         700: {
            itemsToShow: 3.5,
            snapAlign: 'center',
         },
         // 1024 and up
         1024: {
            itemsToShow: 5,
            snapAlign: 'start',
         },
      },
   }),
}
</script>
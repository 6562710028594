<template>
    <!--====== CALL TO ACTION ONE PART START ======-->
    <section class="call-action-area call-action-one">
        <div class="container">
            <div class="row align-items-center call-action-content">
                <div class="col-xl-12 col-lg-12">
                    <div class="call-action-text">
                        <h2 class="action-title">
                            Ready to Take Off ?
                        </h2>

                    </div>
                </div>

            </div>
            <!-- row -->
        </div>
        <!-- container -->
    </section>
    <!--====== CALL TO ACTION ONE PART ENDS ======-->
</template>



<style scoped>
.call-action-one {
    background: #0a082d;
    background: linear-gradient(180deg, #0a082d 0%, #0a082d 35%, rgba(0, 0, 0, 1) 100%);
}

/* ===== Buttons Css ===== */
.call-action-one .call-action-content .call-action-btn .primary-btn {
    background: #ffffff;
    color: white;
    font-size: 25px !important;
    /* box-shadow: var(--shadow-2); */
}

.call-action-one .call-action-content .call-action-btn .active.primary-btn,
.call-action-one .call-action-content .call-action-btn .primary-btn:hover,
.call-action-one .call-action-content .call-action-btn .primary-btn:focus {
    background: #ffffff;
    color: white;
    /* box-shadow: var(--shadow-4); */
}

.call-action-one .call-action-content .call-action-btn .deactive.primary-btn {
    background: grey;
    color: #ffffff;
    pointer-events: visibleStroke;
}

/*===== call action one =====*/
.call-action-one {
    padding: 50px;
}



.call-action-one .call-action-content .call-action-text .action-title {
    font-weight: 600;
    color: #ffffff;
    font-size: 30px !important;
    text-align: center;
}

.call-action-one .call-action-content .call-action-text .text-lg {
    color: #ffffff;
    margin-top: 16px;
    font-size: 20px !important;
    text-align: start;
}

.call-action-one .call-action-content .call-action-btn {
    margin-top: 50px;
}
</style>
<template>
    <!--====== NAVBAR ONE PART START ======-->


    <nav class="navbar navbar-expand-lg fixed-top " :class="navbarClass">
        <div class="container navbar-container ">
            <a class="navbar-brand" @click="goToHome()">
                <img src="../assets/logo.png" width="90" alt="Logo" />
            </a>

            <p class="btn-bars d-none" @click="openSidebar">
                <i class="fa-solid fa-bars"></i>
            </p>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto">


                    <div class="two-item d-flex">
                        <li class="nav-item" @click="goToHome()">
                            <a @click="goToHome()">Home</a>
                        </li>
                        <li class="nav-item" @click="navigateToPage('services')">
                            <router-link to="/services">Services</router-link>
                        </li>
                    </div>

                    <li class="nav-item" @click="navigateToPage('works')">
                        <router-link to="/works">Portfolio</router-link>
                    </li>
                    <li class="nav-item" @click="navigateToPage('about')">
                        <router-link to="/about">About</router-link>
                    </li>
                    <li class="nav-item" @click="navigateToPage('contact')">
                        <router-link to="/contact">Contact</router-link>
                    </li>

                </ul>
            </div>
        </div>
    </nav>



    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
        <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">
                <a @click="goToHome()">
                    <img src="../assets/logo_color.png" alt="Logo" width="60" />
                </a>
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close">X</button>
        </div>
        <div class="offcanvas-body">

            <ul class="navbar-nav ms-auto">

                <li class="nav-item">
                    <a @click="goToHome()">Home</a>
                </li>
                <li class="nav-item">
                    <router-link to="/services">Services</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/works">Works</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/about">About</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/contact">Contact</router-link>
                </li>

            </ul>

        </div>
    </div>


    <div class="sidebar" :class="{ 'is-active': isActive }">
        <p class="close-btn" @click="closeSidebar()"><i class="fa-solid fa-close"></i></p>
        <div class="content">

            <ul class="sidebar-nav ms-auto">

                <li @click="goToHome()" class="nav-item">
                    <a>Home</a>
                </li>
                <li class="nav-item">
                    <router-link to="/services">Services</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/works">Works</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/about">About</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/contact">Contact</router-link>
                </li>

            </ul>
        </div>
    </div>


    <!--====== NAVBAR ONE PART ENDS ======-->

    <!--====== Bootstrap js ======-->
</template>


<script>
export default {
    data() {
        return {
            scrollPosition: 0,
            isActive: false
        }
    },
    methods: {
        openSidebar() {
            this.isActive = true;
        },
        closeSidebar() {
            this.isActive = false;
        },

        goToHome() {
            window.location.href = "/"
        },
        navigateToPage(name) {
            this.$router.push('/' + name)
        },
        updateScroll() {
            this.scrollPosition = window.scrollY || document.documentElement.scrollTop;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.updateScroll);
    },

    computed: {
        navbarClass() {
            return this.scrollPosition > 730 ? 'navbar-bg' : '';
        }
    }
};
</script>



<style scoped>
.sidebar {
    width: 250px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: #007AC0;
    border-right: 1px solid #0a082d;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    z-index: 10000000 !important;
}

.sidebar.is-active {
    transform: translateX(0);
}

.btn-bars {
    font-size: 17px !important;
    color: white !important;
}

.close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #ffffff !important;
    font-size: 15px !important;
}

.sidebar-nav {
    margin-top: 70px !important;
}

.sidebar-nav .nav-item {
    background: #007AC0 !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    margin: 0px 8px !important;
    font-size: 17px !important;
    cursor: pointer !important;
}

.sidebar-nav .nav-item a {
    color: #ffffff !important;
    text-transform: uppercase !important;
    font-family: Poppins-Regular !important;
}






.navbar-nav .nav-item {
    background: white !important;
    padding: 10px 20px !important;
    border-radius: 20px !important;
    margin: 0px 8px !important;
    font-size: 17px !important;
    cursor: pointer !important;
}

.navbar-nav .nav-item a {
    color: #490E74 !important;
    text-transform: uppercase !important;
    font-family: Poppins-Regular !important;
}


.navbar {
    background: transparent !important;
}

.navbar-bg {
    background: #0a082d !important;
}

@media (max-width: 767px) {
    .navbar-brand img {
        width: 50px !important;
    }
}


@media only screen and (max-width: 999px) {
    .navbar-container {
        padding: 7px 16px !important;

    }

    .btn-bars {
        display: block !important;
    }

}


.two-item {
    background: white !important;
    border-radius: 20px !important;

}

.offcanvas {
    background: #0a082d !important;
}

.offcanvas .navbar-nav .nav-item {
    background: #0a082d !important;
    color: white !important;
}

.offcanvas .navbar-nav .nav-item a {
    color: white !important;
}


.navbar-brand {
    cursor: pointer !important;
}
</style>
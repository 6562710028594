<template>
   <!--====== TEAM STYLE ONE START ======-->
   <section class="works-area p-5">
      <div class="container">
         <h1>FEUTURED WORKS</h1>
         <div class="row">
            <div class="col-lg-4 col-md-6">
               <div class="single-team text-center team-style-one ">
                  <div class="team-image">
                     <img
                        src="https://assets-global.website-files.com/64f6d43a08e977747f955d61/656851c54b5f48427b2c8b3d_Korek-(2)-min-p-500.jpg"
                        alt="Team" />
                  </div>
               </div>
               <!-- single team -->
            </div>


            <div class="col-lg-4 col-md-6">
               <div class="single-team text-center team-style-one ">
                  <div class="team-image">
                     <img
                        src="https://assets-global.website-files.com/64f6d43a08e977747f955d61/656851c54dc019c56317e877_Korek-(4a)-min-p-500.jpg"
                        alt="Team" />
                  </div>
               </div>
               <!-- single team -->
            </div>



            <div class="col-lg-4 col-md-6">
               <div class="single-team text-center team-style-one ">
                  <div class="team-image">
                     <img
                        src="https://assets-global.website-files.com/64f6d43a08e977747f955d61/656851c57c0dcf7ed651969e_Korek-(0)-min-p-500.jpg"
                        alt="Team" />
                  </div>
               </div>
               <!-- single team -->
            </div>


         </div>
         <!-- row -->




         <div class="row mt-0">
            <div class="col-lg-4 col-md-6">
               <div class="single-team text-center team-style-one ">
                  <div class="team-image">
                     <img
                        src="https://assets-global.website-files.com/64f6d43a08e977747f955d61/656852192da17400bb8681fc_tornet-(00)-min-p-500.jpg"
                        alt="Team" />
                  </div>
               </div>
               <!-- single team -->
            </div>


            <div class="col-lg-4 col-md-6">
               <div class="single-team text-center team-style-one ">
                  <div class="team-image">
                     <img
                        src="https://assets-global.website-files.com/64f6d43a08e977747f955d61/65685219c86aad532fa41889_tornet-(0a)-min-p-500.jpg"
                        alt="Team" />
                  </div>
               </div>
               <!-- single team -->
            </div>



            <div class="col-lg-4 col-md-6">
               <div class="single-team text-center team-style-one ">
                  <div class="team-image">
                     <img
                        src="https://assets-global.website-files.com/64f6d43a08e977747f955d61/6568521937fbb3820259460d_tornet-(a2)-min-p-500.jpg"
                        alt="Team" />
                  </div>
               </div>
               <!-- single team -->
            </div>


         </div>


      </div>
      <!-- container -->
   </section>
   <!--====== TEAM STYLE ONE ENDS ======-->
</template>



<style scoped>
/*===========================
  teams css
===========================*/

.works-area {
   background: transparent !important;
   padding: 90px !important;
   color: white !important;
}


h1 {
   font-size: 30px !important;
   font-weight: bolder !important;
   color: white !important;
}

.single-team {
   background: transparent !important;
}

.team-style-one {
   position: relative;
   margin-top: 30px;
   border-radius: 8px;
   overflow: hidden;
   -webkit-transition: all 0.4s ease-out 0s;
   -moz-transition: all 0.4s ease-out 0s;
   -ms-transition: all 0.4s ease-out 0s;
   -o-transition: all 0.4s ease-out 0s;
   transition: all 0.4s ease-out 0s;
}

.team-style-one:hover {
   box-shadow: var(--shadow-4);
}

.team-style-one .team-image img {
   width: 100%;
}

.team-style-one .team-content {
   padding: 20px 15px;
   width: 100%;
   background-color: transparent;
}

.team-style-one .team-content .name {
   font-size: 20px;
   font-weight: 600;
   line-height: 30px;
   color: #4A2C82;
   -webkit-transition: all 0.3s ease-out 0s;
   -moz-transition: all 0.3s ease-out 0s;
   -ms-transition: all 0.3s ease-out 0s;
   -o-transition: all 0.3s ease-out 0s;
   transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
   .team-style-one .team-content .name {
      font-size: 20px;
   }
}

.team-style-one .team-content .sub-title {
   font-size: 15px;
   line-height: 24px;
   color: black;
   margin-top: 2px;
   display: block;
}

.team-style-one .team-content .social {
   margin-top: 12px;
}

.team-style-one .team-content .social li {
   display: inline-block;
   margin: 0 4px;
}

.team-style-one .team-content .social li a {
   font-size: 16px;
   color: var(--gray-1);
   height: 35px;
   width: 35px;
   text-align: center;
   line-height: 35px;
   border: 1px solid var(--gray-4);
   border-radius: 50%;
   -webkit-transition: all 0.4s ease-out 0s;
   -moz-transition: all 0.4s ease-out 0s;
   -ms-transition: all 0.4s ease-out 0s;
   -o-transition: all 0.4s ease-out 0s;
   transition: all 0.4s ease-out 0s;
}

.team-style-one .team-content .social li a:hover {
   background-color: var(--primary);
   color: var(--white);
   border-color: transparent;
}
</style>
<template>
  <!--====== FOOTER ONE PART START ======-->




  <footer class="footer-area footer-one">
    <div class="footer-widget">
      <div class="container-fluid">
        <div class="row d-flex justify-content-between flex-wrap">
          <div class="col-xl-6 col-lg-4 col-sm-12" style="align-self: center;">
            <div class="f-about">
              <div class="footer-logo">
                <h2 class="footer-title">Contact Us</h2>

              </div>

            </div>

          </div>


          <div class="col-xl-6 col-lg-4 col-sm-12 contact-section">
            <!-- Start Footer Contact -->
            <div class="footer-contact">

              <ul style="display:inline-block">
                <li class="d-flex justify-content-start">
                  <div class="align-items-center">
                    <i class="fa-solid fa-location-dot mr-5"></i>
                    <span>Media City, Floor 8 Erbil</span>
                  </div>
                </li>
                <li class="d-flex justify-content-start">
                  <div class="align-items-center">
                    <i class="fa-solid fa-phone"></i>
                    <span>9647509838383</span>
                  </div>
                </li>
                <li class="d-flex justify-content-start">
                  <div class="align-items-center">
                    <i class="fa-solid fa-phone"></i>
                    <span>9647719838383</span>
                  </div>
                </li>

                <li class="d-flex justify-content-start">
                  <div class="align-items-center">
                    <i class="fa-solid fa-envelope"></i>
                    <span>info@magency.me</span>
                  </div>
                </li>

              </ul>
            </div>
            <!-- End Footer Contact -->
          </div>



        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!-- footer widget -->
    <div class="footer-copyright">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="
                  copyright
                  text-center
                  d-md-flex
                  justify-content-between
                  align-items-center
                  ">
              <p class="copyright_text">Copyright © 2024 Multimedia Agency. All Rights Reserved</p>
              <ul class="social">
                <li>
                  <div @click="openLink('https://www.facebook.com/multimediaagencyiraq')">
                    <i class="lni lni-facebook"></i>
                  </div>
                </li>
                <!-- <li>
                  <a href="javascript:void(0)">
                    <i class="lni lni-twitter"></i>
                  </a>
                </li> -->
                <li>
                  <div @click="openLink('https://www.instagram.com/multimedia_agency/')">
                    <i class="lni lni-instagram"></i>
                  </div>
                </li>

                <li>
                  <div @click="openLink('https://www.linkedin.com/company/ma-interact/')">
                    <i class="lni lni-linkedin"></i>
                  </div>
                </li>




              </ul>
            </div>
            <!-- copyright -->
          </div>
        </div>
        <!-- row -->
      </div>
      <!-- container -->
    </div>
    <!-- footer copyright -->
  </footer>
  <!--====== FOOTER ONE PART ENDS ======-->
</template>

<script>
export default {
  methods: {
    openLink(link) {
      window.open(link, '_blank');
    },
  },
};
</script>

<style>
/*===== FOOTER ONE =====*/
.footer-one {
  background-color: transparent !important;
}


.contact-section {
  background: #007ac042 !important;
  padding-bottom: 40px !important;

  @media screen and (max-width: 992px) {
    margin-top: 40px !important;
  }
}

.copyright_text {
  color: #d6d6d6 !important;
  font-size: 13px !important;

}

.footer-one .footer-logo {
  margin-top: 30px;
}

.footer-one .footer-widget {
  /* padding-top: 70px; */
  /* padding-bottom: 100px; */
}





.footer-one .footer-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 22px;
  color: rgb(255, 255, 255);
  position: relative;
}

.footer-one .footer-app-store {
  padding-top: 22px;
}

.footer-one .footer-app-store .download-title {
  font-size: 15px;
  display: block;
  margin-bottom: 15px;
  font-weight: 500;
  color: var(--dark-1);
}

.footer-one .footer-app-store li {
  display: inline-block;
  margin-right: 8px;
}

.footer-one .footer-app-store li img {
  max-width: 140px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-one .footer-app-store li {
    margin-right: 6px;
  }
}

.footer-one .footer-app-store li:last-child {
  margin-right: 0;
}

@media (max-width: 767px) {
  .footer-one .footer-app-store li {
    width: 120px;
    display: block;
    margin-bottom: 10px;
  }

  .footer-one .footer-app-store li:last-child {
    margin: 0;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .footer-one .footer-app-store li {
    width: auto;
  }
}

.footer-one .footer-app-store li a {
  -webkit-transition: all 0.2s ease-out 0s;
  -moz-transition: all 0.2s ease-out 0s;
  -ms-transition: all 0.2s ease-out 0s;
  -o-transition: all 0.2s ease-out 0s;
  transition: all 0.2s ease-out 0s;
}

.footer-one .footer-app-store li a:hover {
  box-shadow: var(--shadow-4);
  -webkit-transform: translateY(-4px);
  -moz-transform: translateY(-4px);
  -ms-transform: translateY(-4px);
  -o-transform: translateY(-4px);
  transform: translateY(-4px);
}

.footer-one .f-about {
  margin-top: 30;
}

.footer-one .f-about .text {
  margin-top: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
only screen and (min-width: 1400px) {
  .footer-one .f-about {
    padding-right: 200px;
  }
}

.footer-one .footer-link {
  margin-top: 30px;
}

.footer-one .footer-link ul {
  margin-top: 8px;
}

.footer-one .footer-link ul a {
  font-size: 16px;
  line-height: 24px;
  color: var(--dark-3);
  margin-top: 16px;
  font-weight: 400;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (max-width: 767px) {
  .footer-one .footer-link ul a {
    margin-top: 12px;
  }
}

.footer-one .footer-link ul a:hover {
  color: var(--primary);
}

.footer-one .footer-contact {
  margin-top: 30px;
}

.footer-one .footer-contact ul {
  margin-top: 24px;
}

.footer-one .footer-contact ul li {
  display: block;
  margin-top: 15px;
  position: relative;
  padding-left: 24px;
  color: #ffffff;
  font-size: 17px !important;
}

.footer-one .footer-contact ul li:first-child {
  margin: 0;
}

.footer-one .footer-contact ul li i {
  margin-right: 10px !important;
}

.footer-one .footer-copyright {
  border-top: 1px solid var(--gray-4);
}

.footer-one .copyright {
  padding-bottom: 23px;
  padding-top: 13px;
}

.footer-one .copyright .text {
  color: #fcfcfc !important;
  margin-top: 10px;
}

.footer-one .social {
  margin-top: 10px;
}

.footer-one .social li {
  display: inline-block;
  margin-right: 15px;
}

.footer-one .social li:last-child {
  margin-right: 0;
}

.footer-one .social li a,
.footer-one .social li div {
  font-size: 18px;
  color: white;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  cursor: pointer;
}

.footer-one .social li a:hover {
  color: #007AC0 !important;
}
</style>
<template>
  <!--====== HEADER ONE PART START ======-->
  <section class="header-area header-one">
    <div class="header-content-area">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-12">
            <div class="header-image d-lg-block">
              <div class="image">
                <img
                  src="https://assets-global.website-files.com/64f6d43a08e977747f955d61/651d3f742a214791acfe223e_hand.png"
                  alt="Header" />
              </div>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="header-wrapper">
              <div class="header-content">
                <h1 class="header-title">
                  Get to know us
                </h1>
                <p class="text-lg">
                  Multimedia Agency is the leading provider of comprehensive marketing solutions in Iraq since 2013,
                  dedicated to developing businesses and organizations of all sizes, and helping them achieve their
                  marketing and branding goals.
                </p>
                <!-- <div class="header-btn rounded-buttons">
                  <a class="btn primary-btn-outline btn-lg" href="../about">
                    Learn More
                  </a>
                </div> -->
              </div>
              <!-- header content -->
            </div>
          </div>

        </div>
        <!-- row -->
      </div>
      <!-- container -->

      <!-- header-shape -->
    </div>
    <!-- header content area -->
  </section>


  <!--====== HEADER ONE PART ENDS ======-->
</template>


<script>
import services from '@/components/services.vue';


export default {
  components: {
    services,
  }
}
</script>



<style scoped>
/* ===== Buttons Css ===== */
.header-one .header-content-area .primary-btn-outline {
  /* border-color: var(--primary); */
  color: #0a082d;
}

.header-title {
  font-size: 30px !important;
}

.text-lg {
  font-size: 18px !important;
}

.header-one .header-content-area .active.primary-btn-outline,
.header-one .header-content-area .primary-btn-outline:hover,
.header-one .header-content-area .primary-btn-outline:focus {
  background: #0a082d;
  color: white;
}

.header-one .header-content-area .deactive.primary-btn-outline {
  color: #0a082d;
  border-color: black;
  pointer-events: none;
}

/*===== HEADER ONE =====*/
.header-one {
  position: relative;
}

.header-one .header-content-area {
  background-color: transparent;
  position: relative;
  /* padding: 130px 0; */
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-one .header-content-area {
    /* padding: 100px 0; */
  }
}

@media (max-width: 767px) {
  .header-one .header-content-area {
    padding: 60px 0;
  }
}

@media only screen and (min-width: 1400px) {
  .header-one .header-content-area {
    /* height: 900px; */
  }
}

@media (max-width: 767px) {
  .header-one .header-content-area {
    /* height: 550px; */
  }
}

.header-one .header-content-area .primary-btn-outline {
  border-color: white;
  background-color: white;
  color: #0a082d;
}

.header-one .header-content-area .primary-btn-outline:hover {
  background-color: transparent;
  border-color: white;
  color: white;
}

.header-one .header-wrapper {
  position: relative;
}

.header-one .header-content {
  max-width: 540px;
  position: relative;
  z-index: 5;
}

.header-one .header-title {
  color: white;
  font-weight: 800;
}

.header-one .text-lg {
  color: white;
  font-weight: 300;
  margin-top: 16px;
}

.header-one .header-btn {
  margin-top: 40px;
}

.header-one .header-image {
  text-align: center;
}

.header-one .header-image .image {
  max-width: 730px;
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .header-one .header-image .image {
    max-width: 600px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-one .header-image .image {
    max-width: 550px;
  }
}

.header-one .header-image .image img {
  width: 100%;
}

.header-one .header-shape {
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  width: 100%;
}

.header-one .header-shape img {
  width: 100%;
}
</style>
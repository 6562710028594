import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
const app = createApp(App);
app.use(ElementPlus)
import '../src/assets/css/main.css'

import store from './store/index'

// Set productionTip to false
app.config.productionTip = false;

app.config.errorHandler = function (err, vm, info) {
  console.error('Global Error Handler:', err, info);
  // You can add your custom error handling logic here
};



createApp(App).use(router).use(store).mount('#app')
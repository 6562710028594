import {
    createStore
} from 'vuex'

import services from './services'

const store = createStore({
    modules: {
        services,
    },
})

export default store